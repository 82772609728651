import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // redirects
  {
    path: '/staffweb',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://old.eipl.org/staffweb')
    }
  },
  {
    path: '/bulletinboard/:segment?',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below

      // window.location.replace('https://old.eipl.org/staff/signage/:station')
      window.location.replace(`https://old.eipl.org${to.fullPath}`)
    }
  },
  {
    path: '/staff/signage/:station?',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below

      // window.location.replace('https://old.eipl.org/staff/signage/:station')
      window.location.replace(`https://old.eipl.org${to.fullPath}`)
    }
  },
  {
    path: '/about/',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/about-root.vue'),
    children: [
      {
        path: 'board-of-trustees',
        name: 'board-of-trustees',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/board.vue')
      },
      {
        name: 'about',
        path: ':anchor?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/about.vue')
      }
    ]
  },
  {
    path: '/adult/',
    component: () => import(/* webpackChunkName: "about" */ '@/views/adult/adult-root.vue'),
    children: [
      {
        name: 'adult-services',
        path: 'services',
        component: () => import(/* webpackChunkName: "adult" */ '@/views/adult/services.vue')
      },
      {
        name: 'adult',
        path: '',
        component: () => import(/* webpackChunkName: "adult" */ '@/views/adult/adult.vue')
      }
    ]
  },
  {
    path: '/digital-resources',
    name: 'digital-resources',
    component: () => import(/* webpackChunkName: "resources" */ '@/views/resources/digital-resources.vue')
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/kids/',
    component: () => import(/* webpackChunkName: "kids" */ '@/views/kids/kids-root.vue'),
    children: [
      {
        path: '1kb4k',
        name: '1kb4k',
        component: () => import(/* webpackChunkName: "kids" */ '@/views/kids/1kb4k.vue')
      },
      {
        path: 'discovery-backyard',
        name: 'discovery-backyard',
        component: () => import(/* webpackChunkName: "kids" */ '@/views/kids/discovery-backyard.vue')
      },
      {
        name: 'kids',
        path: ':anchor?',
        component: () => import(/* webpackChunkName: "kids" */ '@/views/kids/kids.vue')
      }
    ]
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: () => import(/* webpackChunkName: "newsletter" */ '@/views/home/news-letter.vue')
  },
  // {
  //   path: '/programs',
  //   name: 'programs',
  //   component: () => import(/* webpackChunkName: "programs" */ '@/views/programs/programs.vue'),
  // },
  {
    path: '/reading-lists/:department?/:list?',
    name: 'reading-lists',
    component: () => import(/* webpackChunkName: "resources" */ '@/views/resources/reading-lists.vue')
  },
  {
    path: '/wifi-guest',
    name: 'wifi-guest',
    component: () => import(/* webpackChunkName: "resources" */ '@/views/resources/wifi-guest.vue')
  },
  {
    path: '/services/',
    children: [
      {
        path: 'blood-pressure-kit',
        name: 'blood-pressure-kit',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/featured.vue')
      },
      {
        path: 'career-counselor',
        name: 'career-counselor',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/career-counselor.vue')
      },
      {
        path: 'discount-tickets',
        name: 'discount-tickets',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/discount-tickets.vue')
      },
      {
        path: 'homebound-service',
        name: 'homebound-service',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/featured.vue')
      },
      {
        path: 'library-card',
        name: 'library-card',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/library-card.vue')
      },
      {
        path: 'library-of-things',
        name: 'library-of-things',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/library-of-things.vue')
      },
      {
        path: 'little-free-library',
        name: 'little-free-library',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/featured.vue')
      },
      {
        path: 'maker-lab',
        name: 'maker-lab',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/maker-lab.vue')
      },
      {
        path: 'museum-passes',
        name: 'museum-passes',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/museum-passes.vue')
      },
      {
        path: '/new-material/:category?',
        name: 'new-material',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/new-material.vue')
      },
      {
        path: 'remote-printing',
        name: 'remote-printing',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/remote-printing.vue')
      },
      {
        path: 'select-books',
        name: 'select-books',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/select-books.vue')
      },
      {
        path: '/',
        name: 'services',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/services.vue')
      }
    ],
    component: () => import(/* webpackChunkName: "services" */ '@/views/services/services-root.vue')
  },
  {
    path: '/teen/',
    component: () => import(/* webpackChunkName: "teen" */ '@/views/teen/teen-root.vue'),
    children: [
      {
        name: 'volunteer-opportunities',
        path: 'volunteer-opportunities',
        component: () => import(/* webpackChunkName: "teen" */ '@/views/teen/volunteer-opportunities.vue')
      },
      {
        name: 'homework-and-research',
        path: 'homework-and-research',
        component: () => import(/* webpackChunkName: "teen" */ '@/views/teen/homework-and-research.vue')
      },
      {
        name: 'teen',
        path: ':anchor?',
        component: () => import(/* webpackChunkName: "teen" */ '@/views/teen/teen.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "home" */ '@/views/admin/admin.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue')
  },
  { path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "home" */ '@/views/page-not-found.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  }
})

export default router
