<script>
  import AlertMessage from '@/components/alert-message.vue'
  import FooterMain from '@/components/footer-main.vue'
  import HeaderMain from '@/components/header-main.vue'
  import ImageModal from '@/components/modal-image.vue'
  import SearchSheet from '@/components/search-sheet.vue'
  import * as api from '@/utils/api.js'
  export default {
    name: 'App',
    components: { AlertMessage, FooterMain, HeaderMain, ImageModal, SearchSheet },
    data: () => ({
      alert: {},
      alertVisible: false,
      scrollTopOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      // scrollTopBtn: false,
      scrollTopTarget: 0
      // snackbar: {
      //   color: 'primary',
      //   show: false,
      //   text: '',
      // },
    }),
    computed: {
      rt() {
        return this.$router
      },
      scrollTopBtn() {
        return this.$store?.state?.scrollPosition > 500
      },
      v() {
        return this.$vuetify
      }
    },
    methods: {
      onScroll(e) {
        // this.scrollTopBtn = e?.srcElement?.scrollingElement?.scrollTop > 500
        this.$store.dispatch('setScrollPosition', e?.srcElement?.scrollingElement?.scrollTop || 0)
      }
      // onSnackBar({ color = 'primary', text = '' }) {
      //   this.snackbar = {
      //     color,
      //     show: true,
      //     text,
      //   }
      // },
    },
    async created() {
      const result = await api.get('home')
      // console.log(result)
      if (result?.alert?.id) {
        this.alert = result.alert
      }
    }
  }
</script>
<template>
  <v-app>
    <header-main :alertVisible="alertVisible"></header-main>
    <v-main v-scroll="onScroll" class="main">
      <alert-message :alert="alert"></alert-message>
      <router-view> </router-view>
      <search-sheet />
    </v-main>
    <!-- SNACKBAR -->
    <!-- <v-snackbar v-model="snackbar.show" app :color="snackbar.color" tile top>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.show = false">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- SCROLL TO TOP BUTTON-->
    <v-fab-transition>
      <v-btn app bottom color="primary" fab fixed out right v-show="scrollTopBtn" @click="$vuetify.goTo(scrollTopTarget, scrollTopOptions)" class="btn-scroll-top">
        <v-icon color="secondary">mdi-chevron-double-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <image-modal />
    <footer-main></footer-main>
  </v-app>
</template>

<style lang="scss">
  /* FONT */

  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

  /*
 <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 200 to 900

.source-sans-3-<uniquifier> {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

*/

  /*
 <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 200 to 900

.source-serif-4-<uniquifier> {
  font-family: "Source Serif 4", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

*/

  // $body-font-family: 'Source Serif 4', serif;
  // $heading-font-family: 'Source Serif 4', serif;

  /* GLOBAL STYLES HERE */

  * {
    box-sizing: border-box;
    font-family: 'Source Sans 3', sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }

  main {
    background-color: #2c477633;
  }

  .bordered {
    border: 1px solid var(--v-primary-base) !important;
  }

  .break-word {
    word-break: break-word;
  }

  .bs-border-box {
    box-sizing: border-box;
  }

  .cursor-arrow {
    cursor: default !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }

  .p-relative {
    position: relative;
  }

  .row {
    margin: 0 !important;
  }

  /* used as the base container on most pages */
  .main-container {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0px;
    padding-bottom: 69px;
  }

  /* APP  */
  .main {
    /* height: 100%; */
    min-height: 100vh;
    margin: 0;
  }

  .btn-scroll-top {
    z-index: 5;
  }
</style>
