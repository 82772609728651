const dateKeyword = date => {
  if (!date) {
    console.log('no valid date received')
    return ''
  }
  const timestamp = timestampSql(date, false)
  const todayTimestamp = timestampSql(null, false)

  const dt = toDateClass(timestamp)
  const todayDt = toDateClass(todayTimestamp)
  const dtTime = dt.getTime()

  //today
  if (dtTime === todayDt.getTime()) {
    return 'Today'
  }
  //yesterday
  todayDt.setDate(todayDt.getDate() - 1)
  if (dtTime === todayDt.getTime()) {
    return 'Yesterday'
  }
  //tomorrow
  todayDt.setDate(todayDt.getDate() + 2)
  if (dtTime === todayDt.getTime()) {
    return 'Tomorrow'
  }
  return date
}

const dayOfWeek = (date, abbreviation = true) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const dt = toDateClass(date)
  const day = dt.getDay()
  return abbreviation ? days[day].substring(0, 3).toUpperCase() : days[day]
}

const humanDate = (datetime, withYear = true, useKeywords = true) => {
  const dt = toDateClass(datetime)
  const month = (dt.getMonth() + 1).toString().padStart(2, '0')
  const day = dt.getDate().toString().padStart(2, '0')
  const year = dt.getFullYear()
  const date = `${month}/${day}`
  const dateWithYear = `${month}/${day}/${year}`
  if (useKeywords) {
    return dateKeyword(dateWithYear)
  }
  return withYear ? dateWithYear : date
}

const humanDateTime = (datetime, withYear = true, useKeywords = true, militaryTime = false) => {
  const dt = toDateClass(datetime)
  const date = humanDate(dt, withYear, useKeywords)
  const time = humanTime(dt, militaryTime)
  return `${date} ${time}`
}

const humanTime = (datetime, isMilitary = false) => {
  const dt = toDateClass(datetime)
  let hours = dt.getHours()
  const minutes = dt.getMinutes().toString().padStart(2, '0')
  if (isMilitary) {
    return `${hours}:${minutes}`
  }
  const period = hours >= 12 ? 'PM' : 'AM'
  hours = hours > 12 ? hours - 12 : hours
  return `${hours}:${minutes}${period}`
}

const monthFromDate = (datetime, abbreviation = false) => {
  const dt = toDateClass(datetime)
  const mth = dt.getMonth()
  const lngth = abbreviation ? 'short' : 'long'
  const monthMap = [
    { short: 'Jan', long: 'January' },
    { short: 'Feb', long: 'February' },
    { short: 'Mar', long: 'March' },
    { short: 'Apr', long: 'April' },
    { short: 'May', long: 'May' },
    { short: 'Jun', long: 'June' },
    { short: 'Jul', long: 'July' },
    { short: 'Aug', long: 'August' },
    { short: 'Sept', long: 'September' },
    { short: 'Oct', long: 'October' },
    { short: 'Nov', long: 'November' },
    { short: 'Dec', long: 'December' }
  ]
  return monthMap?.[mth]?.[lngth] || ''
}

const monthIndexFromName = (month, idxStart = 0) => {
  if (!month || typeof month !== 'string') {
    console.log('monthIndexFromName expects a string for month name')
    return null
  }
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const idx = months.findIndex(m => m.toLocaleLowerCase().includes(month.toLocaleLowerCase()))
  if (idx < 0) return null
  return idx + idxStart
}

const timestampSql = (datetime, withTime = true) => {
  const dt = toDateClass(datetime)
  const adjDt = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000)
  return withTime ? adjDt.toISOString().slice(0, 19).replace('T', ' ') : adjDt.toISOString().split('T')[0]
}

const toDateClass = time => {
  if (!time) return new Date()
  const dt = time && typeof time.getMonth === 'function' ? time : new Date(time)
  return dt
}

export { dateKeyword, dayOfWeek, humanDate, humanDateTime, humanTime, monthFromDate, monthIndexFromName, timestampSql, toDateClass }
