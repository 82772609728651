<script>
  import { monthIndexFromName, timestampSql } from '@/utils/datetime'
  export default {
    name: 'hours-tile',
    data: () => ({
      hours: [
        {
          dayOfWeek: 0,
          open: '13:00',
          close: '17:00',
          day: 'Sunday',
          hours: '1:00 PM - 5:00 PM',
          note: 'October through April'
        },
        {
          dayOfWeek: 1,
          open: '9:00',
          close: '21:00',
          day: 'Monday',
          hours: '9:00 AM - 9:00 PM',
          note: ''
        },
        {
          dayOfWeek: 2,
          open: '9:00',
          close: '21:00',
          day: 'Tuesday',
          hours: '9:00 AM - 9:00 PM',
          note: ''
        },
        {
          dayOfWeek: 3,
          open: '9:00',
          close: '21:00',
          day: 'Wednesday',
          hours: '9:00 AM - 9:00 PM',
          note: ''
        },
        {
          dayOfWeek: 4,
          open: '9:00',
          close: '21:00',
          day: 'Thursday',
          hours: '9:00 AM - 9:00 PM',
          note: ''
        },
        {
          dayOfWeek: 5,
          open: '9:00',
          close: '18:00',
          day: 'Friday',
          hours: '9:00 AM - 6:00 PM',
          note: ''
        },
        {
          dayOfWeek: 6,
          open: '9:00',
          close: '17:00',
          day: 'Saturday',
          hours: '9:00 AM - 5:00 PM',
          note: ''
        }
      ],
      isOpen: true,
      schedule: {}
    }),
    created() {
      //get month
      //test if closed on sunday
      //todo: error test?
      const now = new Date()
      const dow = now.getDay()
      const schedule = this.hours.find(h => h.dayOfWeek === dow)
      this.schedule = schedule
      const todayTimestamp = timestampSql(now, false)
      const openTime = new Date(`${todayTimestamp} ${schedule.open}`).getTime()
      const closeTime = new Date(`${todayTimestamp} ${schedule.close}`).getTime()
      const nowTime = now.getTime()
      // const openTime =
      this.isOpen = openTime < nowTime && nowTime < closeTime
    }
    // delete me when board minutes setup
    // mounted() {
    //   const items = [
    //     { file: 'Bd_agenda_01-23.pdf', type: 'agenda', month: 'January', year: '2023' },
    //     { file: 'minutes_1-23.pdf', type: 'minutes', month: 'January', year: '2023' },
    //     { file: 'Bd_agenda_2-23.pdf', type: 'agenda', month: '', year: '' },
    //     { file: 'minutes_2-23.pdf', type: 'minutes', month: 'February', year: '2023' },
    //     { file: 'Bd_agenda_3-2-23.pdf', type: 'agenda', month: 'March', year: '2023' },
    //     { file: 'minutes_3-23-23.pdf', type: 'minutes', month: 'March', year: '2023' },
    //     { file: 'minutes_3-2-23sp.pdf', type: 'minutes', month: 'March', year: '2023' },
    //     { file: 'Bd_agenda_4-23.pdf', type: 'agenda', month: 'April', year: '2023' },
    //     { file: 'minutes_4-23.pdf', type: 'minutes', month: 'April', year: '2023' },
    //     { file: 'Bd_agenda_4-18-23.pdf', type: 'agenda', month: 'April', year: '2023' },
    //     { file: 'minutes_4-18-23sp.pdf', type: 'minutes', month: 'April', year: '2023' },
    //     { file: 'Bd_agenda_5-9-23.pdf', type: 'agenda', month: 'May', year: '2023' },
    //     { file: 'minutes_5-9-23spmtg.pdf', type: 'minutes', month: 'May', year: '2023' },
    //     { file: 'Bd_agenda_6-23.pdf', type: 'agenda', month: 'June', year: '2023' },
    //     { file: 'minutes_6-23.pdf', type: 'minutes', month: 'June', year: '2023' },
    //     { file: 'Bd_agenda_7-23.pdf', type: 'agenda', month: 'July', year: '2023' },
    //     { file: 'minutes_7-23.pdf', type: 'minutes', month: 'July', year: '2023' },
    //     { file: 'Bd_agenda_7-25-23spmtg.pdf', type: 'agenda', month: '-', year: '-' },
    //     { file: 'minutes_7-25-23spmtg.pdf', type: 'minutes', month: '-', year: '-' },
    //     { file: 'Bd_agenda_8-23.pdf', type: 'agenda', month: 'August', year: '2023' },
    //     { file: 'minutes_8-23.pdf', type: 'minutes', month: 'August', year: '2023' },
    //     { file: 'Bd_agenda_9-23.pdf', type: 'agenda', month: 'September', year: '2023' },
    //     { file: 'minutes_9_23.pdf', type: 'minutes', month: 'September', year: '2023' },
    //     { file: 'Bd_agenda_10-23.pdf', type: 'agenda', month: 'October', year: '2023' },
    //     { file: 'minutes_10_23.pdf', type: 'minutes', month: 'October', year: '2023' },
    //     { file: 'Bd_agenda_11-23.pdf', type: 'agenda', month: 'November', year: 'November' },
    //     { file: 'minutes_11-23.pdf', type: 'minutes', month: 'November', year: '2023' },
    //     { file: 'Bd_agenda_12-23.pdf', type: 'agenda', month: 'December', year: '2023' },
    //     { file: 'minutes_12-23.pdf', type: 'minutes', month: 'December', year: '2023' },
    //     { file: 'Bd_agenda_1-24.pdf', type: 'agenda', month: 'January', year: '2024' },
    //     { file: 'minutes_1-2024.pdf', type: 'minutes', month: 'January', year: '2024' },
    //     { file: 'Bd_agenda_2-24.pdf', type: 'agenda', month: 'February', year: '2024' },
    //     { file: 'minutes_2-24.pdf', type: 'minutes', month: 'February', year: '2024' },
    //     { file: 'Bd_agenda_3-24.pdf', type: 'agenda', month: 'March', year: '2024' },
    //     { file: 'minutes_3-24.pdf', type: 'minutes', month: 'March', year: '2024' },
    //     { file: 'Bd_agenda_4-24.pdf', type: 'agenda', month: 'April', year: '2024' },
    //     { file: 'minutes_4_24.pdf', type: 'minutes', month: 'April', year: '2024' },
    //     { file: 'Bd_agenda_5-24.pdf', type: 'agenda', month: 'May', year: '2024' },
    //     { file: 'minutes_5_24.pdf', type: 'minutes', month: 'May', year: '2024' },
    //     { file: 'Bd_agenda_6-24.pdf', type: 'agenda', month: 'June', year: '2024' },
    //     { file: 'minutes_6-24.pdf', type: 'minutes', month: 'June', year: '2024' },
    //     { file: 'Board-Meeting-Docs_7-2024.pdf', type: 'agenda', month: 'July', year: '2024' },
    //     { file: 'July-Minutes_Purchasing-Policy.pdf', type: 'minutes', month: 'July', year: '2024' },
    //     { file: 'Board-Meeting-Docs_8-2024.pdf', type: 'agenda', month: 'August', year: '2024' },
    //     { file: 'minutes_8-24.pdf', type: 'minutes', month: 'August', year: '2024' },
    //     { file: 'Board-Meeting-Docs_9-2024.pdf', type: 'agenda', month: 'Sept', year: '2024' },
    //     { file: 'minutes_9-24.pdf', type: 'minutes', month: 'September', year: '2024' },
    //     { file: 'Board-Meeting-Agenda-and-Docs_10-2024.pdf', type: 'agenda', month: 'Oct', year: '2024' },
    //     { file: 'minutes_10-24.pdf', type: 'minutes', month: 'October', year: '2024' }
    //   ]

    //   const itemsFixed = items.map(i => {
    //     return { ...i, month: i?.month === '-' ? '-' : monthIndexFromName(i.month) }
    //   })
    //   console.log(itemsFixed)
    //   console.log(JSON.stringify(itemsFixed))
    // }
  }
</script>
<template>
  <v-card active-class="active-override" color="transparent" flat hover :link="false" to="/about/hours" class="d-flex align-center secondary--text">
    <v-tooltip color="primary" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" :color="isOpen ? 'success' : 'error'" large left>mdi-home-clock</v-icon>
      </template>
      <v-sheet v-if="$vuetify.breakpoint.xs" color="transparent" class="d-flex flex-column secondary--text">
        <span class="font-weight-bold text-body-1 text-no-wrap">{{ isOpen ? 'We are open!' : 'We are closed.' }}</span>
        <span class="text-subtitle-2 text-no-wrap"> {{ schedule?.hours || '' }}</span>
      </v-sheet>
    </v-tooltip>

    <v-sheet v-if="$vuetify.breakpoint.smAndUp" color="transparent" class="d-flex flex-column secondary--text">
      <span class="font-weight-bold text-body-1 text-no-wrap">{{ isOpen ? 'We are open!' : 'We are closed.' }}</span>
      <span class="text-subtitle-2 text-no-wrap">{{ schedule?.hours || '' }}</span>
    </v-sheet>
  </v-card>
</template>

<style></style>
