<script>
  import TileDepartmentNav from '@/components/tile-department-nav.vue'
  export default {
    props: {
      breakpoint: {
        //pass in vuetify breakpoints
        type: Object,
        required: true
      }
      // navItems: {
      //   type: Array,
      //   required: true
      // }
    },
    components: {
      TileDepartmentNav
    },
    data: () => ({
      navItemSelected: null,
      highlightSubNavItem: null,
      subNavExpansionPanel: null,

      digitalDownload: [
        {
          href: 'https://search.ebscohost.com/login.aspx?authtype=ip%2Ccpid&custid=scls&profile=eon',
          id: 1,
          logo: 'https://media.eipl.org/site/images/logos/flipster.webp',
          text: 'Flipster'
        },
        {
          href: 'https://www.hoopladigital.com/',
          id: 2,
          logo: 'https://media.eipl.org/site/images/logos/hoopla.jpg',
          text: 'Hoopla'
        },
        {
          href: 'https://www.overdrive.com/apps/libby',
          id: 3,
          logo: 'https://media.eipl.org/site/images/logos/libby.png',
          text: 'Libby'
        },
        {
          href: 'https://www.tumblebooklibrary.com/home.aspx',
          id: 4,
          logo: 'https://media.eipl.org/site/images/logos/tumblebooks.png',
          text: 'Tumblebooks'
        }
      ],
      digitalResearch: [
        {
          href: 'https://ancestrylibrary.proquest.com/aleweb/ale/do/login/sharedip',
          id: 1,
          logo: 'https://media.eipl.org/site/images/logos/ancestry.png',
          text: 'Ancestry'
        },
        {
          href: 'https://0-www-referenceusa-com.search.livebrary.com/',
          id: 2,
          logo: 'https://media.eipl.org/site/images/logos/data-axle.png',
          text: 'Data Axle'
        },
        {
          href: 'https://landing.brainfuse.com/alc/index.asp?u=main.suffolk.ny.brainfuse.com',
          id: 3,
          logo: 'https://media.eipl.org/site/images/logos/livebrary-homework-help.jpg',
          text: 'Homework Help'
        },
        {
          href: 'https://www.livebrary.com/',
          id: 4,
          logo: 'https://media.eipl.org/site/images/logos/livebrary.png',
          text: 'Livebrary Databases'
        }
      ]
    }),
    computed: {
      isXSmall() {
        return this.breakpoint?.name === 'xs'
      },
      menuLabelDirection() {
        const dirMap = {
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
          xl: 'row'
        }
        // const dirMap = {
        //   xs: 'row',
        //   sm: 'column',
        //   md: 'column',
        //   lg: 'row',
        //   xl: 'row',
        // }
        return dirMap[this.$vuetify.breakpoint.name] || 'row'
      }
    },
    methods: {
      menuLabelText(text) {
        const clientW = document.body.clientWidth
        const label = clientW < 400 ? text.split(' ')[0] : text
        //hack to fix "your library"
        return label === 'Your' ? 'Library' : label
      },
      onMenuInput(event, menu) {
        this.navItemSelected = !event || this.navItemSelected === menu ? null : menu
      }
    }
  }
</script>
<template>
  <v-sheet color="transparent" class="nav-row d-flex align-center justify-space-between">
    <!-- :class="$vuetify.breakpoint.xs && 'flex-column'" -->

    <!-- YOUR LIBRARY -->
    <v-card flat class="pa-1 rounded">
      <!-- <v-card v-for="navItem in navItems" :key="'navFlex' + navItem.value" flat tile :width="menuLabelDirection === 'row' ? '100%' : ''" class="pa-1 rounded"> -->
      <!-- :close-on-content-click="breakpoint.mdAndUp ? true : false" -->
      <!--  -->
      <v-menu
        open-on-click
        close-on-click
        :close-on-content-click="true"
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        v-on:input="onMenuInput($event, 'library')"
      >
        <!-- @mouseenter="onMenuInput(true, 'library')" -->
        <!-- <v-menu
        open-on-click
        close-on-click
        :close-on-content-click="true"
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        :value="true"
      > -->
        <template v-slot:activator="{ on }">
          <v-sheet
            v-on="on"
            class="menu-btn primary--text d-flex align-center justify-center text-truncate cursor-pointer"
            :class="`flex-${menuLabelDirection} ${navItemSelected === 'library' ? 'primary secondary--text' : 'secondary primary--text'} `"
            height="45px"
            rounded
            width="100%"
          >
            <!-- :class="`flex-${menuLabelDirection} ${navItemSelected === 'library' ? 'primary secondary--text' : 'secondary primary--text'} `" -->
            <v-icon :left="menuLabelDirection === 'row'" :color="navItemSelected === 'library' ? 'secondary' : 'primary'">mdi mdi-bank</v-icon>
            <font class="font-weight-bold" :class="menuLabelDirection === 'column' ? 'caption' : 'ml-1 '">{{ menuLabelText('Your Library ') }}</font>
          </v-sheet>
        </template>
        <v-card rounded width="98%" :class="breakpoint.smAndDown ? 'mt-4' : 'mt-4'" class="subnav-menu-card mx-auto">
          <v-card-text class="primary--text subNavPanelCardText">
            <v-scale-transition leave-absolute>
              <v-row justify="space-around" align="start">
                <v-col align-self="stretch" cols="12" md="4" class="d-flex flex-column justify-space-between pa-0">
                  <v-card color="transparent" flat>
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-bank</v-icon><span>DEPARTMENTS</span></v-card-title
                    >
                    <v-card-text class="pa-0">
                      <!-- :to="{ name: department.slug }" -->
                      <TileDepartmentNav />
                      <!-- <v-card v-for="department in departments" :key="department.slug" :color="department.color" width="33%" class="mt-2">
                        <v-card-text class="d-flex flex-column align-start justify-start secondary--text font-weight-black text-h5 py-2 pb-1">
                          <p class="mb-0 text-uppercase">{{ department.slug }}</p>
                          <v-sheet color="transparent" class="d-flex">
                            <v-btn color="secondary" x-small text dense class="pl-0"><v-icon left>mdi mdi-home</v-icon>HOME</v-btn>
                            <v-btn color="secondary" x-small text dense class="pl-0"><v-icon left>mdi mdi-calendar</v-icon>PROGRAMS</v-btn>
                          </v-sheet>
                        </v-card-text>
                      </v-card> -->
                    </v-card-text>
                  </v-card>
                  <!-- <v-btn color="primary" text to="/about/board-of-trustees" class="mr-auto pl-2 mt-4 mt-md-0">
                    <v-icon left>mdi-account-tie</v-icon>BOARD OF TRUSTEES & DOCUMENTS</v-btn
                  > -->
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pa-0">
                  <v-card color="transparent" flat class="mt-12 mt-md-0">
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-room-service</v-icon><span>SERVICES</span></v-card-title
                    >
                    <v-card-text class="pa-0">
                      <ul class="font-weight-bold text-h6 primary--text text-decoration-none">
                        <li>
                          <router-link to="/services/blood-pressure-kit" class="nav-link">Blood Pressure Kit</router-link>
                        </li>
                        <li>
                          <router-link to="/services/career-counselor" class="nav-link">Career Counseling</router-link>
                        </li>
                        <li>
                          <router-link to="/services/discount-tickets" class="nav-link">Discount Tickets</router-link>
                        </li>
                        <li>
                          <router-link to="/services/homebound-service" class="nav-link">Homebound Program</router-link>
                        </li>
                        <li>
                          <router-link to="/services/library-card" class="nav-link">Library Card</router-link>
                        </li>
                        <li>
                          <router-link to="/services/library-of-things" class="nav-link">Library of Things</router-link>
                        </li>
                        <li>
                          <router-link to="/services/little-free-library" class="nav-link">Little Free Libraries</router-link>
                        </li>
                        <li>
                          <router-link to="/services/maker-lab" class="nav-link">Maker Lab</router-link>
                        </li>
                        <li>
                          <router-link to="/services/museum-passes" class="nav-link">Museum Passes</router-link>
                        </li>
                        <li>
                          <router-link to="/services/remote-printing" class="nav-link">Remote Printing</router-link>
                        </li>
                        <li>
                          <router-link to="/services/select-books" class="nav-link">Select Books</router-link>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pa-0">
                  <v-card color="transparent" flat class="mt-12 mt-md-0">
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-6">
                      <v-icon color="primary" large class="mr-2">mdi mdi-information</v-icon><span>INFORMATION</span>
                    </v-card-title>
                    <v-card-text class="d-flex flex-column font-weight-bold text-body-1 primary--text text-decoration-none pa-0 pl-2">
                      <v-sheet color="transparent" class="d-flex align-center mb-4">
                        <v-sheet color="transparent" class="d-flex align-center primary--text text-no-wrap mr-4">
                          <v-icon color="primary" left>mdi-phone-classic</v-icon>
                          <p class="ma-0">(631) 581-9200</p>
                        </v-sheet>
                        <v-sheet color="transparent" class="d-flex align-center primary--text text-no-wrap">
                          <v-icon color="primary" left>mdi-email</v-icon>
                          <p class="ma-0">help@eipl.org</p>
                        </v-sheet>
                      </v-sheet>
                      <v-sheet color="transparent" class="d-flex align-start primary--text text-no-wrap mb-4">
                        <v-icon color="primary" left class="mt-2">mdi-map-marker</v-icon>
                        <p class="ma-0">
                          381 East Main Street <br />
                          East Islip, NY 11730
                        </p>
                      </v-sheet>
                      <v-sheet color="transparent" class="d-flex align-start primary--text text-no-wrap mb-4">
                        <v-icon color="primary" left class="mt-2">mdi-clock</v-icon>
                        <v-sheet color="transparent" class="d-flex align-center justify-start primary--text text-left">
                          <!-- TODO: Map To Data Obj -->
                          <!-- move to comp -->
                          <v-sheet color="transparent" width="150px" class="d-flex flex-shrink-1 flex-column text-subtitle-2 primary--text">
                            <p class="font-weight-bold ma-0">MON - THUR:</p>
                            <p class="font-weight-bold ma-0">FRIDAY:</p>
                            <p class="font-weight-bold ma-0">SATURDAY:</p>
                            <p class="font-weight-bold ma-0">SUNDAY* :</p>
                            <p class="font-weight-bold text-caption ma-0">*(Oct. - Apr.)</p>
                          </v-sheet>
                          <v-sheet color="transparent" class="d-flex flex-column align-start primary--text text-center text-subtitle-2 text-no-wrap">
                            <p class="ma-0">9:00 AM - 9:00 PM</p>
                            <p class="ma-0">9:00 AM - 6:00 PM</p>
                            <p class="ma-0">9:00 AM - 5:00 PM</p>
                            <p class="ma-0">1:00 PM - 5:00 PM</p>
                            <p class="ma-0">&nbsp;</p>
                          </v-sheet>
                        </v-sheet>
                      </v-sheet>
                      <!-- <v-btn color="primary" text to="/about/board-of-trustees" class="mr-auto pl-2 ">
                  <v-icon left>mdi-account-tie</v-icon>BOARD OF TRUSTEES & DOCUMENTS</v-btn
                > -->
                      <v-sheet
                        tag="a"
                        href="https://secure.qgiv.com/for/eipl/"
                        target="_blank"
                        class="sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4"
                      >
                        <p class="font-weight-bold text-h6 primary--text text-decoration-none mb-1">Support your library!</p>
                        <img
                          alt="Hands holding up letter balloons spelling the word donate."
                          height="80"
                          width="280"
                          src="https://media.eipl.org/site/images/site/donate.jpg"
                          class="rounded"
                        />
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-scale-transition>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card>

    <!-- BROWSE & BORROW -->
    <v-card flat class="pa-1 rounded">
      <!-- <v-card v-for="navItem in navItems" :key="'navFlex' + navItem.value" flat tile :width="menuLabelDirection === 'row' ? '100%' : ''" class="pa-1 rounded"> -->
      <!-- :close-on-content-click="breakpoint.mdAndUp ? true : false" -->
      <v-menu
        close-on-click
        :close-on-content-click="true"
        open-on-click
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        v-on:input="onMenuInput($event, 'browse')"
      >
        <!-- @mouseenter="onMenuInput(true, 'browse')" -->
        <template v-slot:activator="{ on }">
          <v-sheet
            v-on="on"
            class="menu-btn d-flex align-center justify-center primary--text text-truncate cursor-pointer"
            :class="`flex-${menuLabelDirection} ${navItemSelected === 'browse' ? 'primary secondary--text' : 'secondary primary--text'} `"
            height="45px"
            rounded
            width="100%"
          >
            <!-- :class="`flex-${menuLabelDirection} ${navItemSelected === 'browse' ? 'primary secondary--text' : 'secondary primary--text'} `" -->
            <v-icon :left="menuLabelDirection === 'row'" :color="navItemSelected === 'browse' ? 'secondary' : 'primary'">mdi mdi-book-open-page-variant-outline</v-icon>
            <font class="font-weight-bold" :class="menuLabelDirection === 'column' ? 'caption' : 'ml-1 '">{{ menuLabelText('Browse & Borrow') }}</font>
          </v-sheet>
        </template>
        <v-card rounded width="98%" :class="breakpoint.smAndDown ? 'mt-4' : 'mt-4'" class="subnav-menu-card mx-auto">
          <v-card-text class="primary--text subNavPanelCardText">
            <v-scale-transition leave-absolute>
              <v-row justify="space-around" align="start">
                <v-col cols="12" sm="6" md="4" class="pa-0">
                  <v-card color="transparent" flat>
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-book-open-page-variant-outline</v-icon><span>BROWSE</span></v-card-title
                    >
                    <v-card-text class="d-flex flex-sm-column align-start justify-space-between pa-0">
                      <v-sheet color="transparent" width="100%" class="d-block d-md-block">
                        <p class="text-h6 primary--text font-weight-bold mb-2">CATALOG</p>
                        <ul class="font-weight-bold text-h6 primary--text text-decoration-none text-no-wrap">
                          <li>
                            <a href="https://search.livebrary.com/search~S20/a" target="_blank" class="nav-link">Author</a>
                          </li>
                          <li>
                            <a href="https://search.livebrary.com/search~S20/X" target="_blank" class="nav-link">Keyword</a>
                          </li>
                          <li>
                            <a href="https://search.livebrary.com/search~S20/d" target="_blank" class="nav-link">Subject</a>
                          </li>
                          <li>
                            <a href="https://search.livebrary.com/search~S20/t" target="_blank" class="nav-link">Title</a>
                          </li>
                        </ul>
                      </v-sheet>
                      <v-sheet color="transparent" class="d-none d-md-block">
                        <p class="text-h6 primary--text font-weight-bold mt-0 mt-sm-6 mb-2">NEWSLETTER</p>
                        <a href="https://media.eipl.org/documents/newsletter/NOV-DEC_2024.pdf" target="_blank">
                          <v-hover v-slot="{ hover }">
                            <img
                              src="https://media.eipl.org/site/images/pages/newsletter/2024/nov-dec.png"
                              alt=""
                              height="200px"
                              aspect-ration="4/3"
                              class="rounded"
                              :class="`elevation-${hover ? '10' : '5'}`"
                            />
                          </v-hover>
                        </a>
                      </v-sheet>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="pa-0">
                  <v-card color="transparent" flat class="mt-12 mt-sm-0 mt-md-0">
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-package-variant</v-icon><span>NEW ARRIVALS</span></v-card-title
                    >
                    <v-card-text class="pa-0">
                      <ul class="font-weight-bold text-h6 primary--text text-decoration-none">
                        <li>
                          <router-link to="/new-material/audio-book" class="nav-link">Audiobook</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/bluray" class="nav-link">Bluray</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/dvd" class="nav-link">DVD</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/fiction" class="nav-link">Fiction</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/cd" class="nav-link">Music CD</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/non-fiction" class="nav-link">Non-Fiction</router-link>
                        </li>
                        <li>
                          <router-link to="/new-material/ya" class="nav-link">Young Adult</router-link>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pa-0">
                  <v-card color="transparent" flat class="mt-12 mt-md-0">
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2"> mdi-heart-search</v-icon><span>DISCOVER</span></v-card-title
                    >
                    <v-card-text class="pa-0">
                      <v-row no-gutters>
                        <v-col class="d-block d-md-none">
                          <v-sheet color="transparent">
                            <p class="text-h6 primary--text font-weight-bold mt-0 mt-md-4 mb-2">NEWSLETTER</p>
                            <a href="https://media.eipl.org/documents/newsletter/NOV-DEC_2024.pdf" target="_blank">
                              <v-hover v-slot="{ hover }">
                                <img
                                  src="https://media.eipl.org/site/images/pages/newsletter/2024/nov-dec.png"
                                  alt=""
                                  height="200px"
                                  aspect-ration="4/3"
                                  class="rounded"
                                  :class="`elevation-${hover ? '10' : '5'}`"
                                />
                              </v-hover>
                            </a>
                          </v-sheet>
                        </v-col>

                        <v-col>
                          <v-sheet
                            tag="a"
                            href="https://eiplblog.wordpress.com/"
                            target="_blank"
                            class="sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4 mt-sm-0"
                          >
                            <p class="font-weight-bold text-h6 primary--text text-decoration-none mb-0">Between the Lines</p>
                            <span class="text-caption primary--text text-decoration-none mt-n2 mb-1">Reading Blog</span>
                            <img
                              alt="front of public library at dusk."
                              height="80"
                              width="280"
                              src="https://eiplblog.wordpress.com/wp-content/uploads/2015/04/cropped-eipl22.jpg"
                              class="rounded"
                            />
                          </v-sheet>
                          <v-sheet
                            tag="a"
                            href="https://libraryaware.com/273/Subscribers/Subscribe?optInPageId=f07f0112-afff-4306-99e6-7b2b7c85abc6"
                            target="_blank"
                            class="sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4"
                          >
                            <p class="font-weight-bold text-h6 primary--text text-decoration-none mb-0">NextReads</p>
                            <span class="text-caption primary--text text-decoration-none mt-n2 mb-1">Tailored Newsletters</span>
                            <img alt="front of public library at dusk." height="80" width="280" src="https://media.eipl.org/site/images/site/librarian.jpg" class="rounded" />
                          </v-sheet>
                          <v-card flat to="/services/select-books" class="sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4">
                            <p class="font-weight-bold text-h6 primary--text text-decoration-none mb-0">Select Books</p>
                            <span class="text-caption primary--text text-decoration-none mt-n2 mb-1">Personalized Recommendations</span>
                            <img
                              alt="front of public library at dusk."
                              height="80"
                              width="280"
                              src="https://media.eipl.org/site/images/services/select-books.jpg"
                              class="rounded"
                              style="object-position: top center; object-fit: cover"
                            />
                          </v-card>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" >
                        <v-sheet color="transparent" class="d-none d-md-block">
                        <p class="text-h6 primary--text font-weight-bold mt-4 mb-2">NEWSLETTER</p>
                        <a href="https://media.eipl.org/documents/newsletter/NOV-DEC_2024.pdf" target="_blank">
                          <v-hover v-slot="{ hover }">
                            <img src="https://media.eipl.org/site/images/pages/newsletter/2024/nov-dec.png" alt="" height="200px" aspect-ration="4/3" class="rounded" :class="`elevation-${hover ? '10' : '5'}`" />
                          </v-hover>
                        </a>
                      </v-sheet>
                      </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- </template> -->
              </v-row>
            </v-scale-transition>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card>

    <!-- DIGITAL RESOURCES -->
    <v-card flat class="pa-1 rounded">
      <!-- <v-card v-for="navItem in navItems" :key="'navFlex' + navItem.value" flat tile :width="menuLabelDirection === 'row' ? '100%' : ''" class="pa-1 rounded"> -->
      <!-- :close-on-content-click="breakpoint.mdAndUp ? true : false" -->
      <v-menu
        open-on-click
        close-on-click
        :close-on-content-click="true"
        elevation="0"
        min-width="100%"
        bottom
        nudge-bottom
        offset-y
        transition="scroll-y-transition"
        v-on:input="onMenuInput($event, 'digital')"
      >
        <!-- @mouseenter="onMenuInput(true, 'digital')" -->
        <template v-slot:activator="{ on }">
          <v-sheet
            v-on="on"
            class="menu-btn primary--text d-flex align-center justify-center text-truncate cursor-pointer"
            :class="`flex-${menuLabelDirection} ${navItemSelected === 'digital' ? 'primary secondary--text' : 'secondary primary--text'} `"
            height="45px"
            rounded
            width="100%"
          >
            <!-- :class="`flex-${menuLabelDirection} ${navItemSelected === 'digital' ? 'primary secondary--text' : 'secondary primary--text'} `" -->
            <v-icon :left="menuLabelDirection === 'row'" :color="navItemSelected === 'digital' ? 'secondary' : 'primary'">mdi mdi-cloud-download-outline</v-icon>
            <font class="font-weight-bold" :class="menuLabelDirection === 'column' ? 'caption' : 'ml-1 '">{{ menuLabelText('Digital Resources') }}</font>
          </v-sheet>
        </template>
        <v-card rounded width="98%" :class="breakpoint.smAndDown ? 'mt-4' : 'mt-4'" class="subnav-menu-card mx-auto">
          <v-card-text class="primary--text subNavPanelCardText">
            <v-scale-transition leave-absolute>
              <v-row justify="space-around" align="start">
                <v-col cols="12" sm="6">
                  <v-card color="transparent" flat width="100%">
                    <v-card-title class="d-flex align-center justify-start flex-nowrap text-no-wrap text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-cloud-download-outline</v-icon><span>DOWNLOAD & STREAM</span></v-card-title
                    >
                    <v-card-text class="d-flex flex-column align-start justify-start text-decoration-none pa-0">
                      <v-card v-for="item in digitalDownload" :key="item.id" hover :href="item.href" target="_blank" width="90%" class="mt-2">
                        <v-card-text class="d-flex flex-row align-center justify-start text-decoration-none pa-2">
                          <v-avatar size="40" color="primary" class="mr-4">
                            <img :src="item.logo" alt="alt" />
                          </v-avatar>
                          <p class="text-h6 primary--text font-weight-bold mb-0">
                            {{ item.text }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="transparent" flat class="mt-12 mt-sm-0 mt-md-0">
                    <v-card-title class="d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
                      ><v-icon color="primary" large class="mr-2">mdi mdi-school</v-icon><span>RESEARCH</span></v-card-title
                    >
                    <v-card-text class="pa-0">
                      <v-card v-for="item in digitalResearch" :key="item.id" hover :href="item.href" target="_blank" width="90%" class="mt-2">
                        <v-card-text class="d-flex flex-row align-center justify-start text-decoration-none pa-2">
                          <v-avatar size="40" color="primary" class="mr-4">
                            <img :src="item.logo" alt="alt" />
                          </v-avatar>
                          <p class="text-h6 primary--text font-weight-bold mb-0">
                            {{ item.text }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-scale-transition>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card>
  </v-sheet>
</template>

<style scoped>
  .nav-row {
    /* display: flex;
    align-content: center;
    justify-content: space-between; */
    /* flex-direction: column; */
    gap: 1rem;
    width: 100%;
  }

  .nav-row > * {
    flex: 1 1 33.333%;
  }

  .menu-btn:hover {
    background-color: var(--v-primary-base) !important;
    color: var(--v-secondary-base) !important;
  }

  .menu-btn:hover > i {
    color: var(--v-secondary-base) !important;
  }

  .v-menu__content {
    box-shadow: none;
  }

  .secondary-nav-panel-content {
    max-height: 20rem;
    overflow-y: auto;
  }

  li:hover {
  }

  .nav-link,
  .sheet-anchor p {
    width: 80%;
    display: block;
    text-decoration: none;
    /* border-bottom-right-radius: 15px;
    border-top-right-radius: 15px; */
  }

  .nav-link:hover {
    /* background-color: rgba(0, 0, 102, 0.35); */
    background: linear-gradient(to right, #00006640 40%, #00006622 60%, #00006610 70%, #ffffff00 100%);
  }

  .sheet-anchor:hover p {
    /* background-color: rgba(0, 0, 102, 0.35); */
  }

  .subnav-menu-card {
    max-height: 70vh;
    overflow-y: auto;
    border: 1px solid var(--v-primary-base) !important;
  }

  /* TESTING BACKGROUND LOGO */
  /* .subNavPanelCardText::before {
  content: ' ';
  background-image: url('@/static/eiplogo_white.svg');
  background-size: cover;
  position: absolute;
  top: 150px;
  right: 0px;
  bottom: 0px;
  left: 150px;
  opacity: 0.05;
  transform: translate(-50%);
} */
</style>
