var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "color": "primary",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "secondary--text ml-2",
          attrs: {
            "color": "warning",
            "large": "",
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "alert-icon"
        }, [_vm._v("mdi-bell-alert")])], 1)];
      }
    }])
  }, [_c('span', {
    staticClass: "font-weight-bold pa-4"
  }, [_vm._v("Important Notices")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }