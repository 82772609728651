import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imageModal: null,
    scrollPosition: 0,
    searchInput: null,
    searchResults: null,
    searchScope: 'catalog',
    searchSheetShow: false,
    searchType: 'keyword'
  },
  getters: {},
  actions: {
    setImageModal({ commit }, image) {
      commit('COMMIT_IMAGE_MODAL', image ? image : null)
    },
    setScrollPosition({ commit }, position) {
      commit('COMMIT_SCROLL_POSITION', position)
    },
    setSearchInput({ commit }, value = '') {
      commit('COMMIT_SEARCH_INPUT', value.toLowerCase())
    },
    setSearchScope({ commit }, value = '') {
      commit('COMMIT_SEARCH_SCOPE', value)
    },
    setSearchType({ commit }, value = '') {
      commit('COMMIT_SEARCH_TYPE', value)
    },
    toggleSearchSheet({ commit, state }, show = null) {
      const val = show === null ? !state.searchSheetShow : Boolean(show)
      commit('COMMIT_SEARCH_SHEET_SHOW', val)
    }
  },
  mutations: {
    COMMIT_IMAGE_MODAL(state, val) {
      state.imageModal = val
    },
    COMMIT_SCROLL_POSITION(state, val) {
      state.scrollPosition = val
    },
    COMMIT_SEARCH_INPUT(state, val) {
      state.searchInput = val
    },
    COMMIT_SEARCH_SCOPE(state, val) {
      state.searchScope = val
    },
    COMMIT_SEARCH_SHEET_SHOW(state, val) {
      state.searchSheetShow = val
    },
    COMMIT_SEARCH_TYPE(state, val) {
      state.searchType = val
    }
  }
  // modules: {},
})
