var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "overlay-opacity": "0",
      "persistent": false,
      "scrollable": "",
      "value": _vm.searchSheetShow,
      "width": "100%"
    },
    on: {
      "click:outside": _vm.toggleSheet
    }
  }, [_c('v-card', {
    attrs: {
      "height": _vm.setSheetHeight(),
      "width": "100%"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "d-flex align-center font-weight-bold"
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("mdi-book-search")]), _vm._v(" SEARCH")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.toggleSheet
    }
  }, [_c('v-icon', [_vm._v("mdi-magnify-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('search-form', {
    attrs: {
      "showOptions": true
    }
  }), _c('v-card', [_c('v-card-text', {
    staticClass: "d-flex align-center justify-center font-weight-bold primary--text"
  }, [!_vm.searchResults ? _c('p', [_vm._v(" " + _vm._s(_vm.searchScope === 'catalog' ? 'Catalog search results will be displayed in a new tab.' : 'Library search results will be displayed here.') + " ")]) : _vm._e()])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }