<script>
  export default {
    name: 'image-modal',
    data: () => ({}),
    computed: {},
    methods: {
      onInput(e) {
        if (!e) {
          this.$store.dispatch('setImageModal', null)
        }
      }
    },
    created() {}
  }
</script>

<template>
  <!-- @click="onLeftClick" -->
  <v-dialog :value="$store?.state?.imageModal" :max-width="600" transition="dialog-transition" @input="onInput">
    <v-card flat color="transparent" @click="onInput(false)">
      <!-- todo set default not found image -->
      <v-img ref="imageDisplayed" :src="$store?.state?.imageModal || ''"  max-height="90vh" max-width="90vw"></v-img>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
