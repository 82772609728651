var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-row flex-md-column align-start justify-space-between text-decoration-none",
    attrs: {
      "color": "transparent",
      "width": "100%"
    }
  }, _vm._l(_vm.departments, function (department) {
    return _c('v-sheet', {
      key: department.slug,
      staticClass: "d-flex flex-column",
      attrs: {
        "width": _vm.$vuetify.breakpoint.smAndDown ? '30%' : '80%'
      }
    }, [_c('v-card', {
      staticClass: "mb-4",
      attrs: {
        "color": department.color,
        "hover": "",
        "to": {
          path: ("/" + (department.slug))
        }
      }
    }, [_c('v-card-text', {
      staticClass: "d-flex flex-column align-center align-sm-start justify-center secondary--text font-weight-black text-h5 pa-2",
      staticStyle: {
        "height": "100%"
      }
    }, [_c('p', {
      staticClass: "mb-0 text-uppercase text-center"
    }, [_vm._v(" " + _vm._s(department.slug) + " ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('v-sheet', {
      staticClass: "d-flex flex-row align-end justify-space-between justify-sm-start mt-auto mt-sm-2",
      attrs: {
        "color": "transparent",
        "width": "100%"
      }
    }, [_c('v-btn', {
      staticClass: "pl-0",
      attrs: {
        "color": "secondary",
        "icon": _vm.$vuetify.breakpoint.name === 'xs',
        "text": "",
        "x-small": _vm.$vuetify.breakpoint.name === 'sm'
      }
    }, [_c('v-icon', {
      attrs: {
        "small": _vm.$vuetify.breakpoint.name === 'sm'
      }
    }, [_vm._v("mdi mdi-home")]), _c('span', {
      staticClass: "d-none d-sm-inline"
    }, [_vm._v("HOME")])], 1), _c('v-btn', {
      staticClass: "pl-0 ml-0 ml-sm-4",
      attrs: {
        "color": "secondary",
        "icon": _vm.$vuetify.breakpoint.name === 'xs',
        "text": "",
        "href": department.programUrl,
        "target": "_blank",
        "x-small": _vm.$vuetify.breakpoint.name === 'sm'
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": _vm.$vuetify.breakpoint.name === 'sm'
      }
    }, [_vm._v("mdi mdi-calendar")]), _c('span', {
      staticClass: "d-none d-sm-inline"
    }, [_vm._v("PROGRAMS")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-btn', {
      staticClass: "d-block d-sm-none",
      attrs: {
        "color": department.color,
        "text": "",
        "href": department.programUrl,
        "target": "_blank"
      }
    }, [_vm._v("PROGRAMS")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }