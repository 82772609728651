<script>
export default {
  name: 'footer-main',
  data: () => ({
    socialIcons: [
      {
        href: 'https://www.facebook.com/eastislippubliclibrary/',
        icon: 'mdi-facebook',
        title: 'Facebook',
      },
      {
        href: 'https://www.instagram.com/theeastislippubliclibrary',
        icon: 'mdi-instagram',
        title: 'Instagram',
      },
      {
        href: 'https://www.linkedin.com/company/east-islip-public-library',
        icon: 'mdi-linkedin',
        title: 'Linkedin',
      },
      {
        href: 'https://www.pinterest.com/eiplib/',
        icon: 'mdi-pinterest',
        title: 'PiNterest',
      },
      {
        href: 'https://twitter.com/eiplib',
        icon: 'mdi-twitter',
        title: 'Twitter',
      },
      {
        href: 'https://www.youtube.com/EIPLibrary',
        icon: 'mdi-youtube',
        title: 'Youtube',
      },
    ],
  }),
}
</script>
<template>
  <v-footer padless>
    <v-card color="primary" flat min-width="100%" tile class="footer-container">
      <v-card-text class="d-flex justify-center">
        <v-sheet
          color="transparent"
          max-width="24rem"
          width="100%"
          class="d-flex align-center justify-space-between pt-4"
        >
          <v-btn
            v-for="icon in socialIcons"
            :key="icon.icon"
            :href="icon.href"
            large
            target="_blank"
            :title="icon.title || ''"
            class="white--text"
            icon
          >
            <v-icon large>
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-sheet>
      </v-card-text>

      <v-card-text class="secondary--text my-8">
        <v-row align="start" justify="center">
          <v-col
            cols="12"
            md="4"
            lg="3"
            fill-height
            class="d-flex align-center justify-center"
          >
            <v-card
              color="transparent"
              flat
              tile
              class="info-card secondary--text"
            >
              <v-card-title class="justify-start">
                <v-icon color="secondary" left>mdi-phone-classic</v-icon>CONTACT
                US
              </v-card-title>
              <v-card-text
                color="transparent"
                class="secondary--text text-left"
              >
                <v-sheet
                  color="transparent"
                  class="d-flex align-center secondary--text"
                  ><strong> PHONE:</strong>
                  <a
                    href="tel:+6315819200"
                    class="contact-link secondary--text ml-2"
                    >(631) 581-9200</a
                  ></v-sheet
                >
                <v-sheet
                  color="transparent"
                  class="d-flex align-center secondary--text"
                  ><strong>E-MAIL:</strong>
                  <a
                    href="mailto:help@eipl.org"
                    class="contact-link secondary--text ml-2"
                    >help@eipl.org</a
                  ></v-sheet
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            fill-height
            class="d-flex align-center justify-center"
          >
            <v-card
              color="transparent"
              flat
              tile
              class="info-card secondary--text"
            >
              <v-card-title class="justify-start">
                <v-icon color="secondary" left>mdi-map-marker</v-icon> FIND US
              </v-card-title>
              <v-card-text
                color="transparent"
                class="secondary--text text-left"
              >
                <p class="text=left ma-0">381 East Main Street</p>
                <p class="text=left ma-0">East Islip, NY 11730</p>
                <a
                  href="https://www.google.com/maps/dir//East+Islip+Public+Library,+East+Main+Street,+East+Islip,+NY/@40.7358185,-73.2083772,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e8338949a41f37:0x60d81b56a4cb6efc!2m2!1d-73.1733617!2d40.7357697"
                  rel="no-referrer"
                  target="_blank"
                >
                  <v-img
                    alt="google map location of building"
                    cover
                    height="auto"
                    max-width="300px"
                    width="100%"
                    position="center center"
                    src="https://media.eipl.org/site/images/site/location-map.png"
                    class="rounded mt-2"
                  />
                </a>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            fill-height
            class="d-flex align-center justify-center"
          >
            <v-card
              color="transparent"
              flat
              tile
              class="info-card secondary--text"
            >
              <v-card-title class="justify-start">
                <v-icon color="secondary" left>mdi-clock</v-icon> VISIT US
              </v-card-title>
              <v-card-text
                color="transparent"
                class="d-flex align-center justify-start secondary--text text-left"
              >
                <!-- TODO: Map To Data Obj -->
                <!-- move to comp -->
                <v-sheet
                  color="transparent"
                  width="150px"
                  class="d-flex flex-shrink-1 flex-column secondary--text"
                >
                  <p class="font-weight-bold ma-0">MONDAY:</p>
                  <p class="font-weight-bold ma-0">TUESDAY:</p>
                  <p class="font-weight-bold ma-0">WEDNESDAY:</p>
                  <p class="font-weight-bold ma-0">THURSDAY:</p>
                  <p class="font-weight-bold ma-0">FRIDAY:</p>
                  <p class="font-weight-bold ma-0">SATURDAY:</p>
                  <p class="font-weight-bold ma-0">SUNDAY* :</p>
                  <p class="font-weight-bold ma-0">*(Oct. - Apr.)</p>
                </v-sheet>
                <v-sheet
                  color="transparent"
                  class="d-flex flex-column secondary--text text-center text-no-wrap"
                >
                  <p class="ma-0">9:00 AM - 9:00 PM</p>
                  <p class="ma-0">9:00 AM - 9:00 PM</p>
                  <p class="ma-0">9:00 AM - 9:00 PM</p>
                  <p class="ma-0">9:00 AM - 9:00 PM</p>
                  <p class="ma-0">9:00 AM - 6:00 PM</p>
                  <p class="ma-0">9:00 AM - 5:00 PM</p>
                  <p class="ma-0">1:00 PM - 5:00 PM</p>
                  <p class="ma-0">&nbsp;</p>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="building-image-container pa-0">
        <!-- <v-img src="/twitterlogo.jpg" height="auto" max-width="73px"></v-img> -->
        <v-img
          cover
          height="auto"
          max-width="100%"
          min-height="200px"
          min-width="200px"
          position="left top"
          width="100%"
          src="https://media.eipl.org/site/images/building-angle-2-bg.png"
          class="building-image"
        ></v-img>
        <!-- <v-img
          cover
          height="auto"
          max-height="400px"
          max-width="700px"
          min-height="200px"
          min-width="200px"
          position="left top"
          width="100%"
          src="https://media.eipl.org/site/images/building-cutout-clean.png"
          class="building-image"
        ></v-img> -->
        <p
          class="copyright-text secondary--text text-center font-weight-black mb-0"
        >
          {{ `© ${new Date().getFullYear()}` }} EIPL.ORG
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
* {
  line-height: 1.8;
}
p {
  margin-bottom: 0;
  padding: 0;
}

.info-card {
  height: 100%;
  /* width: 18rem; */
  font-weight: bold;
  /* max-width: 20rem; */
  /* width: 100%;
    min-width: 15rem;
    flex: 1 1 100%; */
  max-width: 600px;
  width: 100%;
}

.contact-link {
  display: block;
  line-height: 2;
  text-decoration: none;
}
.building-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;
}

.building-image {
  position: absolute;
  /* bottom: -20px; */
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: auto;
}

.copyright-text {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
