var render = function () {
  var _vm$$store, _vm$$store$state, _vm$$store2, _vm$$store2$state;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": (_vm$$store = _vm.$store) === null || _vm$$store === void 0 ? void 0 : (_vm$$store$state = _vm$$store.state) === null || _vm$$store$state === void 0 ? void 0 : _vm$$store$state.imageModal,
      "max-width": 600,
      "transition": "dialog-transition"
    },
    on: {
      "input": _vm.onInput
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        return _vm.onInput(false);
      }
    }
  }, [_c('v-img', {
    ref: "imageDisplayed",
    attrs: {
      "src": ((_vm$$store2 = _vm.$store) === null || _vm$$store2 === void 0 ? void 0 : (_vm$$store2$state = _vm$$store2.state) === null || _vm$$store2$state === void 0 ? void 0 : _vm$$store2$state.imageModal) || '',
      "max-height": "90vh",
      "max-width": "90vw"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }