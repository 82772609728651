<script>
  import AlertsButton from '@/components/alerts-button.vue'
  import HoursTile from '@/components/hours-tile.vue'
  import NavBreadcrumbs from '@/components/nav-breadcrumbs.vue'
  import NavMain from '@/components/nav-main.vue'
  import tempNavData from '@/static/tempNavData.js'

  const { navMainItems } = tempNavData
  export default {
    name: 'header-main',
    components: { AlertsButton, HoursTile, NavBreadcrumbs, NavMain },
    data: () => ({
      collapseNav: false,
      lastScrollPos: 0,
      navHeaderItems: [
        {
          //   text: 'About',
          icon: 'mdi-information',
          text: 'About',
          title: 'Hours, Directions, Policies, Board of Trustees.',
          to: '/about',
          value: 'about'
        },
        {
          href: 'https://search.livebrary.com/patroninfo~S20/',
          // href: 'https://search.livebrary.com/iii/cas/login',
          icon: 'mdi-account',
          text: 'Account',
          title: 'Sign into your library account.',
          value: 'account'
        },
        {
          icon: 'mdi-book-search',
          text: 'Search',
          title: 'Search library catalog and website.',
          value: 'search'
        }
      ],
      searchShow: false
    }),
    computed: {
      alerts() {
        return this?.$store?.state?.alerts || []
      },
      breakpoint() {
        return this.$vuetify.breakpoint
      },

      extensionHeight() {
        //TODO SET REF ON ALERT, ADJUST HEIGHT
        //ALERT MOVED TO HOME
        // let height = this.searchShow ? 250 : 75
        // if (this.alertVisible) height += 75
        let height
        if (!this.collapseNav) {
          height = this.showBreadcrumbs ? 118 : 70
        } else {
          height = this.showBreadcrumbs ? 40 : 0
        }

        return height
      },
      height() {
        // let height = this.$route.name === 'home' ? 120 : 140
        const height = this.collapseNav ? 100 : 120
        return height
      },
      scrollPosition() {
        return this.$store.state.scrollPosition
      },
      showBreadcrumbs() {
        return this.$route.name !== 'index' && this.$route.name !== 'home'
      }
    },
    methods: {
      navClicks(item) {
        console.log(item)
        if (item === 'search') {
          this.$store.dispatch('toggleSearchSheet', !this.$store.state.searchSheetShow)
        }
      }
    },
    watch: {
      scrollPosition() {
        if (Math.abs(this.lastScrollPos - this.scrollPosition) > 500) {
          this.collapseNav = this.scrollPosition > 500
          this.lastScrollPos = this.scrollPosition
        }
      }
    }
  }
</script>
<template>
  <v-app-bar app color="primary" :extension-height="extensionHeight" :height="height" class="main-header">
    <v-img
      src="https://media.eipl.org/site/images/logos/eiplogo_transparent.gif"
      cover
      height="auto"
      :max-width="collapseNav ? '180px' : '300px'"
      min-width="100px"
      width="100%"
      position="left, top"
      @click="$router.push({ path: '/' })"
      class="cursor-pointer flex-shrink-1 mb-auto"
      style="transition: all 0.75s"
    ></v-img>

    <v-spacer></v-spacer>
    <!-- PICKUP HERE, FIX WHERE BREAK POINTS HIDE ICON TEXT -->
    <!-- PICKUP HERE, FIX LOGO SIZING -->
    <v-sheet color="transparent" height="100%" class="d-flex flex-column align-end justify-start">
      <v-sheet color="transparent" class="d-flex align-end justify-end" style="max-height: 60px">
        <!-- <v-toolbar-items class="align-end justify-end" style="max-height: 60px"> -->
        <v-tooltip v-for="navItem in navHeaderItems" :key="navItem.value" color="primary" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-large
              :href="navItem.href ? navItem.href : null"
              :icon="breakpoint.name === 'xs'"
              :target="navItem.href ? '_blank' : undefined"
              :text="breakpoint.name !== 'xs'"
              :to="navItem.to ? navItem.to : undefined"
              @click="!navItem.href && navClicks(navItem.value)"
              class="secondary--text px-sm-4"
            >
              <v-icon color="secondary" md :left="breakpoint.name !== 'xs'">{{ navItem.icon }}</v-icon>
              {{ breakpoint.name !== 'xs' ? navItem.text : '' }}
            </v-btn>
          </template>
          <span class="text-no-wrap font-weight-bold pa-4">{{ navItem.title }}</span>
        </v-tooltip>
      </v-sheet>

      <!-- </v-toolbar-items> -->
      <v-sheet v-if="!collapseNav" color="transparent" class="d-flex align-center mt-2">
        <hours-tile />
        <!-- <alerts-button v-if="alerts" /> -->
      </v-sheet>
      <v-btn v-else icon dark @click="collapseNav = false"><v-icon>mdi-menu-open</v-icon></v-btn>
    </v-sheet>

    <template v-slot:extension>
      <v-sheet color="transparent" height="100%" width="100%" class="d-flex flex-column justify-space-between">
        <nav-main v-if="!collapseNav" :breakpoint="breakpoint"></nav-main>
        <v-sheet color="transparent" class="d-flex align-end justify-space-between">
          <nav-breadcrumbs :show="showBreadcrumbs" :class="collapseNav ? 'py-0' : 'py-4'"></nav-breadcrumbs>
        </v-sheet>
      </v-sheet>
    </template>
  </v-app-bar>
</template>

<style scoped>
  .main-header {
    z-index: 99;
  }

  .main-nav {
    transition: 1s;
    /* opacity: .2; */
  }

  >>> .v-toolbar__content {
    transition: all 0.5s;
    overflow: hidden;
  }
  >>> .v-toolbar__extension {
    transition: all 0.5s;
    overflow: hidden;
  }

  .active-override.v-btn::before {
    background-color: unset;
  }
</style>
