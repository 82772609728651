const navHeaderItems = [
  {
    //   text: 'About',
    icon: 'mdi-information',
    text: 'About',
    title: 'Hours, Directions, Policies, Board of Trustees.',
    to: '/about',
    value: 'about',
  },
  {
    href: 'https://search.livebrary.com/iii/cas/login',
    icon: 'mdi-account',
    text: 'Account',
    title: 'Sign into your library account.',
    value: 'account',
  },
  {
    icon: 'mdi-book-search',
    text: 'Search',
    title: 'Search library catalog and website.',
    value: 'search',
  },
]

const navMainItems = [
  {
    text: 'Browse & Borrow',
    value: 'browse',
    icon: 'mdi-book-open-page-variant-outline',
    subnav: [
      {
        text: 'OUR COLLECTION',
        icon: 'mdi-book-open-page-variant-outline',
        links: [
          {
            text: 'Search the catalog',
            url: '#'
          },
          {
            text: 'Encore-county wide search',
            url: '#'
          },
          {
            text: 'New Fiction',
            url: '#'
          },
          {
            text: 'New Non-Fiction',
            url: '#'
          },
          {
            text: 'New Audio Books',
            url: '#'
          },
          {
            text: 'New Music cds',
            url: '#'
          }
        ]
      },
      {
        text: 'STAY CONNECTED',
        icon: 'mdi-share-variant',
        links: [
          {
            text: 'Facebook',
            url: '#'
          },
          {
            text: 'Twitter',
            url: '#'
          },
          {
            text: 'YouTube',
            url: '#'
          },
          {
            text: 'Pinterest',
            url: '#'
          }
        ]
      },
      {
        text: 'READER RESOURCES',
        icon: 'mdi-magnify',
        links: [
          {
            text: 'Between-the-Lines our reader’s blog',
            url: '#'
          },
          {
            text: 'Find Your Next Read',
            url: '#'
          },
          {
            text: 'Select Books –let us help you compile a list of good reads',
            url: '#'
          },
          {
            text: 'Good Reads',
            url: '#'
          }
        ]
      }
    ]
  },
  {
    text: 'Library Resources',
    value: 'resources',
    icon: 'mdi-bank',
    subnav: [
      {
        text: 'ABOUT US',
        icon: 'mdi-information',
        links: [
          {
            text: 'Hours & Holiday Closings',
            url: '#'
          },
          {
            text: 'Directions',
            url: '#'
          },
          {
            text: 'Library Newsletter',
            url: '#'
          },
          {
            text: 'Borrowing Materials',
            url: '#'
          },
          {
            text: 'Suffolk County Libraries',
            url: '#'
          },
          {
            text: 'History of the Library',
            url: '#'
          },
          {
            text: 'East Islip Arts Council',
            url: '#'
          },
          {
            text: 'Library Policies',
            url: '#'
          },
          {
            text: 'Board of Trustees',
            url: '#'
          }
        ]
      },
      {
        text: 'SERVICES',
        icon: 'mdi-room-service',
        links: [
          {
            text: 'Discovery Backyard',
            url: '#'
          },
          {
            text: 'Museum Passes',
            url: '#'
          },
          {
            text: 'Wireless Printing',
            url: '#'
          },
          {
            text: 'Free Permit Practice Tests',
            url: '#'
          },
          {
            text: 'Mobile Hotspots',
            url: '#'
          }
        ]
      },
      {
        text: 'LOCAL INFORMATION',
        icon: 'mdi-map-marker-question',
        links: [
          {
            text: 'Voter Registration Search',
            url: '#'
          },
          {
            text: 'IRS Forms',
            url: '#'
          },
          {
            text: 'NYS Tax Forms',
            url: '#'
          },
          {
            text: 'NYS of Health',
            url: '#'
          },
          {
            text: 'Re-apply for STAR',
            url: '#'
          },
          {
            text: 'Historic Islip Trail',
            url: '#'
          },
          {
            text: 'My HealtheVet',
            url: '#'
          },
          {
            text: 'The AFC (Affordable Care Act)',
            url: '#'
          },
          {
            text: 'Islip Emergency Alert Service',
            url: '#'
          },
          {
            text: 'Report Electric Outage 800-490-0075',
            url: '#'
          },
          {
            text: 'Islip Town Emergency 224-5600',
            url: '#'
          },
          {
            text: 'Suffolk Police Notification System',
            url: '#'
          },
          {
            text: 'Boating Certificate Repl. Form',
            url: '#'
          },
          {
            text: "Gov't Information",
            url: '#'
          },
          {
            text: 'Unlock your government',
            url: '#'
          },
          {
            text: 'Your Elected Officials',
            url: '#'
          },
          {
            text: 'Bus Schedules: S-40 and 3-C',
            url: '#'
          },
          {
            text: 'Old Long Island',
            url: '#'
          },
          {
            text: 'LIRR Schedules',
            url: '#'
          },
          {
            text: 'Social Security Express',
            url: '#'
          },
          {
            text: 'Long Island Index Reports',
            url: '#'
          }
        ]
      }
    ]
  },
  {
    text: 'Research & Learn',
    value: 'research',
    icon: 'mdi-school',
    subnav: [
      {
        text: 'ONLINE RESEARCH',
        icon: 'mdi-web',
        links: [
          {
            text: 'Live-brary',
            url: '#'
          },
          {
            text: 'Ref US – data on 44million businesses',
            url: '#'
          },
          {
            text: 'Live Homework Help',
            url: '#'
          },
          {
            text: 'Live-brary for Kids',
            url: '#'
          },
          {
            text: 'Pronunciator – learn a language',
            url: '#'
          },
          {
            text: 'Ancestry.com',
            url: '#'
          }
        ]
      },
      {
        text: 'LEARNING RESOURCES ',
        icon: 'mdi-school',
        links: [
          {
            text: 'Multi-cultural/Language Learning Center',
            url: '#'
          },
          {
            text: 'Career/Employment/College Community Resources',
            url: '#'
          },
          {
            text: 'Local History Long Island',
            url: '#'
          },
          {
            text: '9/11 Project',
            url: '#'
          }
        ]
      },
      {
        text: 'DOWNLOADS & STREAMING',
        icon: 'mdi-cloud-download',
        links: [
          {
            text: 'Free Downloads with Live-brary - Digital downloads with your library card',
            url: '#'
          },
          {
            text: 'Download audiobooks, e-books, videos, music and e-magazines',
            url: '#'
          },
          {
            text: 'Overdrive - sign in',
            url: '#'
          },
          {
            text: 'New to Flipster? - instructions',
            url: '#'
          },
          {
            text: 'Flipster Magazines- direct link',
            url: '#'
          },
          {
            text: 'One Click Digital - Download audio books',
            url: '#'
          },
          {
            text: 'Need assistance downloading e-books?',
            url: '#'
          }
        ]
      }
    ]
  }
]
export default { navHeaderItems, navMainItems }
