<script>
  import { mapState } from 'vuex'
  import searchForm from '@/components/search-form.vue'
  export default {
    name: 'search-sheet',
    components: {
      searchForm,
    },
    computed: {
      ...mapState({
        searchInput: state => state.searchInput,
        searchResults: state => state.searchResults,
        searchScope: state => state.searchScope,
        searchSheetShow: state => state.searchSheetShow,
        searchTopic: state => state.searchTopic,
      }),
    },
    methods: {
      setSheetHeight() {
        const docHeight = document?.documentElement?.clientHeight || 0
        const appTop = this.$vuetify?.application?.top || 0
        const height = docHeight - appTop
        // console.log(height <= 0 ? '100%' : height)
        return height <= 0 ? '100%' : height
      },
      toggleSheet() {
        console.log('toggle')
        this.$store.dispatch('toggleSearchSheet', !this.$store.state.searchSheetShow)
      },
    },
  }
</script>
<template>
  <v-bottom-sheet overlay-opacity="0" :persistent="false" scrollable :value="searchSheetShow" width="100%" @click:outside="toggleSheet">
    <v-card :height="setSheetHeight()" width="100%">
      <v-toolbar color="primary" dark>
        <v-toolbar-title class="d-flex align-center font-weight-bold"> <v-icon color="secondary" left>mdi-book-search</v-icon> SEARCH</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="toggleSheet">
          <v-icon>mdi-magnify-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4" style="height: 100%">
        <v-container fluid>
          <search-form :showOptions="true" />
          <v-card>
            <v-card-text class="d-flex align-center justify-center font-weight-bold primary--text">
              <p v-if="!searchResults">
                {{ searchScope === 'catalog' ? 'Catalog search results will be displayed in a new tab.' : 'Library search results will be displayed here.' }}
              </p>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<style scoped>
  >>> .v-sheet.v-card {
    border-radius: 0px;
  }
</style>
