<script>
  export default {
    name: 'nav-department-tiles',
    data: () => ({
      departments: [
        {
          slug: 'adult',
          color: 'primary',
          programUrl: 'https://eastislip.librarycalendar.com/events/month?age_groups%5B4%5D=4&age_groups%5B5%5D=5'
        },
        {
          slug: 'kids',
          color: '#4caf50',
          programUrl:
            'https://eastislip.librarycalendar.com/events/month?age_groups%5B2%5D=2&age_groups%5B252%5D=252&age_groups%5B249%5D=249&age_groups%5B250%5D=250&age_groups%5B251%5D=251&age_groups%5B5%5D=5'
        },
        {
          slug: 'teen',
          color: 'cyan',
          programUrl: 'https://eastislip.librarycalendar.com/events/month?age_groups%5B3%5D=3'
        }
      ]
    }),
    computed: {},
    methods: {},
    created() {}
  }
</script>

<template>
  <v-sheet color="transparent" width="100%" class="d-flex flex-row flex-md-column align-start justify-space-between text-decoration-none">
    <v-sheet v-for="department in departments" :key="department.slug" :width="$vuetify.breakpoint.smAndDown ? '30%' : '80%'" class="d-flex flex-column">
      <v-card :color="department.color" hover :to="{ path: `/${department.slug}` }" class="mb-4">
        <!-- :style="$vuetify.breakpoint.name === 'xs' && 'aspect-ratio: 1'" -->
        <v-card-text class="d-flex flex-column align-center align-sm-start justify-center secondary--text font-weight-black text-h5 pa-2" style="height: 100%">
          <!-- class="d-flex flex-column align-center align-sm-start justify-center secondary--text font-weight-black text-h5 pa-2 pb-0 pb-sm-1" -->
          <p class="mb-0 text-uppercase text-center">
            {{ department.slug }}
          </p>
          <v-sheet v-if="$vuetify.breakpoint.smAndUp" color="transparent" width="100%" class="d-flex flex-row align-end justify-space-between justify-sm-start mt-auto mt-sm-2">
            <v-btn color="secondary" :icon="$vuetify.breakpoint.name === 'xs'" text :x-small="$vuetify.breakpoint.name === 'sm'" class="pl-0"
              ><v-icon :small="$vuetify.breakpoint.name === 'sm'">mdi mdi-home</v-icon><span class="d-none d-sm-inline">HOME</span></v-btn
            >
            <!-- :to="{ name: 'programs', params: { department: department.slug } }" -->
            <v-btn
              color="secondary"
              :icon="$vuetify.breakpoint.name === 'xs'"
              text
              :href="department.programUrl"
              target="_blank"
              :x-small="$vuetify.breakpoint.name === 'sm'"
              class="pl-0 ml-0 ml-sm-4"
              @click.stop=""
              ><v-icon :small="$vuetify.breakpoint.name === 'sm'">mdi mdi-calendar</v-icon><span class="d-none d-sm-inline">PROGRAMS</span></v-btn
            >
          </v-sheet>
        </v-card-text>
      </v-card>

      <v-btn :color="department.color" text :href="department.programUrl" target="_blank" class="d-block d-sm-none">PROGRAMS</v-btn>
    </v-sheet>
  </v-sheet>
</template>

<style scoped></style>
