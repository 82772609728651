var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('v-breadcrumbs', {
    staticClass: "d-flex align-center secondary--text font-weight-bold px-0",
    attrs: {
      "items": _vm.breadCrumbItems,
      "active-class": "breadCrumbActive",
      "color": "secondary"
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "px-0",
          attrs: {
            "color": "secondary"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (props) {
        var _props$item;

        return [_c('router-link', {
          staticClass: "breadCrumbs secondary--text font-weight-bold",
          style: props !== null && props !== void 0 && (_props$item = props.item) !== null && _props$item !== void 0 && _props$item.isFinal ? {
            'text-decoration': 'underline'
          } : '',
          attrs: {
            "exact": "",
            "to": props.item.href
          }
        }, [props.item.icon ? _c('v-icon', {
          attrs: {
            "color": "secondary",
            "small": ""
          }
        }, [_vm._v(_vm._s(props.item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(props.item.text.toUpperCase()))], 1)];
      }
    }], null, false, 2489077665)
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }